import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import renderFormikError from '../../elements/renderFormikError';
import * as Yup from 'yup';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import BlockScreen from '../blockScreen/blockScreen';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/es';
import { OrganizationalUnit, BenefitCenter, InternationalFactor, InternationalAllowanceByRole, Vehicle, MetropolitanRegionTripConstant, NationalTripConstant, NationalFactor, Season, SeasonByMonth } from '../../types/fields';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import formatRUT from '../../utils/formatRUT';
import debounce from '../../utils/debounce';
import performApiRequest from '../../utils/performApiRequest';
import { useToast } from '../../context/useToast';
import { useDataForEndpoint } from '../../context/dataProvider';
import Loader from '../../elements/loader';
import { useAccessPermissions } from '../../hooks/useAccessPermission';

const requireDepositOptions = [
    {id: false, label: 'No'},
    {id: true, label: 'Sí'}
]

type RequestData = {
    id: number;
    requesting_employee_rut: string;
    form_completer_user_rut: string;
    benefit_center_prc_code: string;
    requesting_unit_id: string;
    requires_deposit: boolean;
    bank_name: string;
    viatic_type: string;
    project_number: string;
    account_type: string;
    account_number: string;
    requested_vehicle_id: number;
    travel_start_date: string;
    travel_end_date: string;
    trip_reason: string
}

type RequestDetails = {
    employee_responsibility_type?: string;
    full_day_basic_viatic?: number;
    half_day_basic_viatic?: number;
    destination_city?: string;
    destination_country_id?: number;
    breakfast_days_only?: number;
    lunch_days_only?: number;
    dinner_days_only?: number;
    meal_vouchers_discount?: number;
    full_meal_days?: number;
    destination_region_id?: number;
    housing_days?: number;
    compensatory_bonus_quantity?: number;
}

type ModifyTravelAllowanceRequestData = {
    requestData?: RequestData;
    requestDetails?: RequestDetails
}

let baseSchema = Yup.object().shape({
    is_it_for_another_employee: Yup.bool().required('Debes seleccionar una opción.'),
    requesting_employee_rut: Yup.string().when('is_it_for_another_employee', (is_it_for_another_employee: any, schema: Yup.StringSchema) =>
        // eslint-disable-next-line eqeqeq
        is_it_for_another_employee == "true" ? schema.required('Ingresa el RUT del usuario.').min(8, 'Ingresa el dígito verificador.') : schema.notRequired()
    ),
    benefit_center_prc_code: Yup.string().required('Debes seleccionar un centro de beneficio.'),
    requesting_unit_id: Yup.string().required('Debes seleccionar una unidad organizacional.'),
    requires_deposit: Yup.bool().required('Debes seleccionar una opción.'),
    bank_name: Yup.string().when('requires_deposit', (requires_deposit: any, schema: Yup.StringSchema) => 
        // eslint-disable-next-line eqeqeq
        requires_deposit == "true" ? schema.required('Escribe el nombre del banco.') : schema.notRequired()
    ),
    viatic_type: Yup.string().oneOf(['Nacional - Región Metropolitana', 'Nacional - Otras regiones', 'Internacional'], 'Debes seleccionar una de las tres opciones.').required('Debes seleccionar un tipo de viático.'),
    project_number: Yup.string().required('Debes indicar el no. de proyecto o si no tiene.'),
    account_type: Yup.string().when('requires_deposit', (requires_deposit: any, schema: Yup.StringSchema) =>
        // eslint-disable-next-line eqeqeq
        requires_deposit == "true" ? schema.oneOf(['Cuenta vista', 'Cuenta corriente', 'Cuenta de ahorro', 'Cuenta chequera electrónica'], 'Debes elegir una de las opciones disponibles').required('Debes seleccionar una de las opciones.') : schema.notRequired()
    ),
    account_number: Yup.string().when('requires_deposit', (requires_deposit: any, schema: Yup.StringSchema) =>
        // eslint-disable-next-line eqeqeq
        requires_deposit == "true" ? schema.required('Ingresa el número de cuenta.') : schema.notRequired()
    ),
    requested_vehicle_id: Yup.number(),
});

const metropolitanRegionTripSchema = Yup.object().shape({
    travel_start_date: Yup.string().required('Selecciona la fecha de inicio.'),
    travel_end_date: Yup.string().required('Selecciona la fecha de término.'),
    days_spent: Yup.number().required('Ingresa la cantidad de días de duración del viaje.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    trip_reason: Yup.string().required('Debes ingresar el motivo del viaje.'),
    breakfast_days_only: Yup.number().required('Debes ingresar la cantidad de días.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    lunch_days_only: Yup.number().required('Debes ingresar la cantidad de días.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    dinner_days_only: Yup.number().required('Debes ingresar la cantidad de días.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    meal_vouchers_discount: Yup.number().required('Debes ingresar la cantidad de días.').min(0, 'Debe ser un valor mayor o igual a cero.')
});

const nationalTripSchema = Yup.object().shape({
    travel_start_date: Yup.string().required('Selecciona la fecha de inicio.'),
    travel_end_date: Yup.string().required('Selecciona la fecha de término.'),
    days_spent: Yup.number().required('Ingresa la cantidad de días de duración del viaje.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    trip_reason: Yup.string().required('Debes ingresar el motivo del viaje.'),
    breakfast_days_only: Yup.number().required('Debes ingresar la cantidad de días.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    lunch_days_only: Yup.number().required('Debes ingresar la cantidad de días.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    dinner_days_only: Yup.number().required('Debes ingresar la cantidad de días.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    meal_vouchers_discount: Yup.number().required('Debes ingresar la cantidad de días.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    full_meal_days: Yup.number().required('Debes ingresar la cantidad de días.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    destination_region_id: Yup.number().required('Debes ingresar la cantidad de días.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    housing_days: Yup.number().required('Debes ingresar la cantidad de días de alojamiento.'),
    compensatory_bonus_quantity: Yup.number().required('Debes ingresar la cantidad de días de bono.').min(0, 'Debe ser un valor mayor o igual a cero.'),
});

const internationalTripSchema = Yup.object().shape({
    travel_start_date: Yup.string().required('Selecciona la fecha de inicio.'),
    travel_end_date: Yup.string().required('Selecciona la fecha de término.'),
    days_spent: Yup.number().required('Ingresa la cantidad de días de duración del viaje.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    trip_reason: Yup.string().required('Debes ingresar el motivo del viaje.'),
    employee_responsibility_type: Yup.string().oneOf(['Encargado (a) de área (gerente)', 'Colaborador (a) de proyecto'], 'Debe seleccionar una de las opciones').required('Selecciona una opción.'),
    full_day_basic_viatic: Yup.number().required('Debes ingresar la cantidad de días.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    half_day_basic_viatic: Yup.number().required('Debes ingresar la cantidad de días.').min(0, 'Debe ser un valor mayor o igual a cero.'),
    destination_city: Yup.string().required('Debes escribir la ciudad de destino.'),
    destination_country_id: Yup.number().required('Debes seleccionar el país de destino.'),
});

const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    esES,
);

const TravelAllowanceRequestForm: React.FC<ModifyTravelAllowanceRequestData> = () => {
    const isAuthenticated = useAuthStatus();
    const [step, setStep] = useState(1);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [availableVehicles, setAvailableVehicles] = useState<Vehicle[]>([]);
    const [viaticType, setViaticType] = useState('Nacional - Región Metropolitana');
    const nationalFactors = useDataForEndpoint<NationalFactor[]>('national_factors');
    const seasonByMonths = useDataForEndpoint<SeasonByMonth[]>('season_by_months');
    const seasons = useDataForEndpoint<Season[]>('seasons');
    const organizationalUnits = useDataForEndpoint<OrganizationalUnit[]>('organizational_units');
    const benefitCenters = useDataForEndpoint<BenefitCenter[]>('benefit_centers');
    const internationalAllowanceByRoles = useDataForEndpoint<InternationalAllowanceByRole[]>('international_allowance_by_roles')
    const metropolitanRegionTripConstants = useDataForEndpoint<MetropolitanRegionTripConstant[]>('metropolitan_region_trip_constants');
    const nationalTripConstants = useDataForEndpoint<NationalTripConstant[]>('national_trip_constants')
    const internationalFactors = useDataForEndpoint<InternationalFactor[]>('international_factors');
    const [isLoading, setIsLoading] = useState(true);
    const [seasonName, setSeasonName] = useState('')
    const navigate = useNavigate();
    const location = useLocation();
    const { requestData, requestDetails } = location.state || {};
    const { showSuccessToast, showErrorToast } = useToast();
    const [totalAmount, setTotalAmount] = useState<Number>(0);
    const { accessPermissions } = useAccessPermissions();
    const [hasNoProjectNumber, setHasNoProjectNumber] = useState(false);
    const nationalRequestEndpoint = `/v1/travelAllowanceRequestController/createNationalTripRequest`
    const metropolitanRegionRequestEndpoint = `/v1/travelAllowanceRequestController/createMetropolitanRegionTripRequest`
    const internationalRequestEndpoint = `/v1/travelAllowanceRequestController/createInternationalTripRequest`
    const modifyNationalRequestEndpoint = `/v1/travelAllowanceRequestController/updateNationalTripRequest`
    const modifyMetropolitanRegionRequestEndpoint = `/v1/travelAllowanceRequestController/updateMetropolitanRegionTripRequest`
    const modifyInternationalRequestEndpoint = `/v1/travelAllowanceRequestController/updateInternationalTripRequest`
    
    useEffect(() => {
        const allData = [
          nationalFactors,
          seasonByMonths,
          seasons,
          organizationalUnits,
          benefitCenters,
          internationalAllowanceByRoles,
          metropolitanRegionTripConstants,
          nationalTripConstants,
          internationalFactors,
        ];
        
        // Check if any of the data arrays is undefined, indicating it hasn't loaded
        const isAnyDataUndefined = allData.some(data => data === undefined);
    
        // Set loading to false if all data is loaded
        setIsLoading(isAnyDataUndefined);
    }, [nationalFactors, seasonByMonths, seasons, organizationalUnits, benefitCenters, internationalAllowanceByRoles, metropolitanRegionTripConstants, nationalTripConstants, internationalFactors]);
    
    async function handleApiRequest(values: any, requestData: any) {
        const typeToEndpointMap = {
            'Nacional - Región Metropolitana': requestData?.id ? modifyMetropolitanRegionRequestEndpoint : metropolitanRegionRequestEndpoint,
            'Nacional - Otras regiones': requestData?.id ? modifyNationalRequestEndpoint : nationalRequestEndpoint,
            'Internacional': requestData?.id ? modifyInternationalRequestEndpoint : internationalRequestEndpoint,
        };
    
        const endpoint = typeToEndpointMap[values.viatic_type as keyof typeof typeToEndpointMap];
        if (!endpoint) {
            console.error('Invalid viatic type');
            return;
        }
    
        const requestOptions = {
            method: requestData?.id ? 'PATCH' : 'POST',
            data: values,
        };
    
        try {
            const response = await performApiRequest(requestData?.id ? `${endpoint}/${requestData.id}` : endpoint, requestOptions);
            if (response.status === 201) {
                showSuccessToast('Solicitud enviada correctamente. No olvides subir tus archivos para justificar el viático.');
                navigate('/allMyTravelAllowanceRequests');
            } else if (response.status === 200) {
                showSuccessToast('Solicitud modificada correctamente.');
                navigate('/allMyTravelAllowanceRequests');
            }
        } catch (error: any) {
            console.error(error);
            showErrorToast(error.response?.data?.message || 'Ocurrió un error. Contactar a Soporte a través del correo sti@dictuc.cl');
        }
    }
    const formik = useFormik({
        initialValues: {
            is_it_for_another_employee: requestData ? (requestData.formCompleterUserData.rut === requestData.requestingUserData.rut) : false,
            requesting_employee_rut: requestData?.requestingUserRUT || '',
            benefit_center_prc_code: requestData?.benefit_center_prc_code || '',
            requesting_unit_id: requestData?.requesting_unit_id || accessPermissions?.unit_id || '',
            requires_deposit: requestData?.requires_deposit || false,
            bank_name: requestData?.bank_name || '',
            viatic_type: requestData?.viatic_type || '',
            project_number: requestData?.project_number || '',
            account_type: requestData?.account_type || '',
            account_number: requestData?.account_number || '',
            trip_reason: requestData?.trip_reason || '',
            requested_vehicle_id: requestData?.requested_vehicle_id || 0,
            travel_start_date: requestData?.travel_start_date || '',
            travel_end_date: requestData?.travel_end_date || '',
            employee_responsibility_type: requestDetails?.employee_responsibility_type || '',
            full_day_basic_viatic: requestDetails?.full_day_basic_viatic || 0,
            half_day_basic_viatic: requestDetails?.half_day_basic_viatic || 0,
            destination_city: requestDetails?.destination_city || '',
            destination_country_id: requestDetails?.destination_country_id || 0,
            breakfast_days_only: requestDetails?.breakfast_days_only || 0,
            lunch_days_only: requestDetails?.lunch_days_only || 0,
            dinner_days_only: requestDetails?.dinner_days_only || 0,
            meal_vouchers_discount: requestDetails?.meal_vouchers_discount || 0,
            full_meal_days: requestDetails?.full_meal_days || 0,
            destination_region_id: requestDetails?.destination_region_id || 0,
            housing_days: requestDetails?.housing_days || 0,
            compensatory_bonus_quantity: requestDetails?.compensatory_bonus_quantity || 0,
            days_spent: requestDetails?.days_spent || 0
        },
        validationSchema: step === 1 ? baseSchema : viaticType === 'Nacional - Región Metropolitana' ? metropolitanRegionTripSchema : 
            (viaticType === 'Nacional - Otras regiones' ? nationalTripSchema : internationalTripSchema),
        onSubmit: async (values) => {
          if (step === 1) {
            setViaticType(values.viatic_type);
            setStep(2);
          } else {
            if (requestData?.id || !requestData) {
                await handleApiRequest(values, requestData);
            } else {
                showErrorToast('Ocurrió un error. Inténtalo de nuevo.')
            }
        }
    }});

    useEffect(() => {
        const calculateTotalAmount = () => {
          let total = 0;
      
          switch (formik.values.viatic_type) {
            case 'Nacional - Región Metropolitana':
              total += calculateMetropolitanTotal();
              break;
            case 'Nacional - Otras regiones':
              total += calculateNationalTotal();
              break;
            case 'Internacional':
              total += calculateInternationalTotal();
              break;
            default:
              break;
          }
      
          return total;
        };
      
        const calculateMetropolitanTotal = () => {
            let totalAmount = 0;
            if (formik.values.viatic_type === 'Nacional - Región Metropolitana') {
              totalAmount += (formik.values.breakfast_days_only * (metropolitanRegionTripConstants.find(constant => constant.item === 'breakfast_days_only')?.uf_amount || 0));
              totalAmount += (formik.values.lunch_days_only * (metropolitanRegionTripConstants.find(constant => constant.item === 'lunch_days_only')?.uf_amount || 0));
              totalAmount += (formik.values.dinner_days_only * (metropolitanRegionTripConstants.find(constant => constant.item === 'dinner_days_only')?.uf_amount || 0));
              totalAmount -= (formik.values.meal_vouchers_discount * (metropolitanRegionTripConstants.find(constant => constant.item === 'meal_voucher_discount_quantity')?.uf_amount || 0));
            }
            setTotalAmount(totalAmount);
            return totalAmount;
        };
      
        const calculateNationalTotal = () => {
            let total = 0;
            console.log(nationalFactors);
            total += formik.values.breakfast_days_only * (nationalTripConstants.find(constant => constant.item === 'breakfast_days_only')?.uf_amount || 0);
            total += formik.values.lunch_days_only * (nationalTripConstants.find(constant => constant.item === 'lunch_days_only')?.uf_amount || 0);
            total += formik.values.dinner_days_only * (nationalTripConstants.find(constant => constant.item === 'dinner_days_only')?.uf_amount || 0);
            total -= formik.values.meal_vouchers_discount * (nationalTripConstants.find(constant => constant.item === 'meal_voucher_discount_quantity')?.uf_amount || 0);
            total += formik.values.full_meal_days * (nationalTripConstants.find(constant => constant.item === 'full_meal_days')?.uf_amount || 0);
            total += formik.values.housing_days * (nationalTripConstants.find(constant => constant.item === 'housing_days')?.uf_amount || 0);

            const seasonalRegionFactor = getNationalFactorValue();
            console.log('El multiplicador de temporada es: ', seasonalRegionFactor);

            let totalUF = total * Number(seasonalRegionFactor);

            totalUF += formik.values.compensatory_bonus_quantity * (nationalTripConstants.find(constant => constant.item === 'compensatory_bonus')?.uf_amount || 0);

            setTotalAmount(totalUF);

            return totalUF;
        };
      
        const calculateInternationalTotal = () => {
            const { full_day_basic_viatic, half_day_basic_viatic, employee_responsibility_type, destination_country_id } = formik.values;
            const allowanceDetails = internationalAllowanceByRoles.find(role => role.role === employee_responsibility_type);
            const internationalFactor = internationalFactors?.find((country: any) => country.id === Number(destination_country_id));
            if (!allowanceDetails) return 0;
            if (!internationalFactor) return 0;

            const totalAmount = ((full_day_basic_viatic * allowanceDetails.usd) + 
                                 (half_day_basic_viatic * allowanceDetails.usd * 0.5)) * internationalFactor.factor;
            
            setTotalAmount(totalAmount);
            return totalAmount;
        };
            setTotalAmount(calculateTotalAmount());
    }, // eslint-disable-next-line
    [
        metropolitanRegionTripConstants,
        nationalFactors,
        nationalTripConstants,
        seasonName,
        internationalFactors,
        internationalAllowanceByRoles,
        formik.values,
        formik.values.viatic_type,
        formik.values.breakfast_days_only,
        formik.values.lunch_days_only,
        formik.values.dinner_days_only,
        formik.values.meal_vouchers_discount,
        formik.values.full_meal_days,
        formik.values.housing_days,
        formik.values.compensatory_bonus_quantity,
        formik.values.destination_region_id,
        formik.values.employee_responsibility_type,
        formik.values.full_day_basic_viatic,
        formik.values.half_day_basic_viatic,
        formik.values.destination_country_id,
    ]);

    useEffect(() => {
        if (formik.values.travel_start_date && formik.values.travel_end_date) {
            const days = calculateInclusiveDaysBetweenDates(formik.values.travel_start_date, formik.values.travel_end_date);
            formik.setFieldValue('days_spent', days);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.travel_start_date, formik.values.travel_end_date]);

    const handleStartDateChange = (date: Dayjs | null) => {
        const formattedDate = date ? date.format('YYYY-MM-DD') : '';
        setSeasonName(determinateSeason(formattedDate));
        formik.setFieldValue('travel_start_date', formattedDate);
    };

    const handleFinishDateChange = (date: Dayjs | null) => {
        const formattedDate = date ? date.format('YYYY-MM-DD') : '';
        formik.setFieldValue('travel_end_date', formattedDate);
    };

    const handleRUTChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedRUT = formatRUT(e.target.value);
        formik.setFieldValue('requesting_employee_rut', formattedRUT);
    };

    const debouncedFetchVehicles = React.useMemo(() => debounce(fetchAvailableVehicles, 500), []);

    async function fetchAvailableVehicles (start: string, end: string) {
        if (!start || !end) return;
        setIsLoading(true);
        try {
            const response = await performApiRequest(`/v1/vehicleController/availableVehicles?startDate=${start}&endDate=${end}`);
            setAvailableVehicles(response.data);
        } catch (error) {
            console.error('Error fetching available vehicles:', error);
            setAvailableVehicles([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        debouncedFetchVehicles(formik.values.travel_start_date, formik.values.travel_end_date);
    }, [debouncedFetchVehicles, formik.values.travel_start_date, formik.values.travel_end_date]);


    function getNationalFactor () {
        return (
            <div className="text-center text-gray-700 font-semibold">x {seasonName === 'Temporada alta' ?
                nationalFactors.find(constant => constant.id === Number(formik.values.destination_region_id))?.high_season_factor :
                nationalFactors.find(constant => constant.id === Number(formik.values.destination_region_id))?.low_season_factor}
            </div>
        )
    }

    function getNationalFactorValue () : number {
        const nationalFactor = (seasonName === 'Temporada alta') ?
            nationalFactors.find(constant => constant.id === Number(formik.values.destination_region_id))?.high_season_factor :
            nationalFactors.find(constant => constant.id === Number(formik.values.destination_region_id))?.low_season_factor ?? 1;
        return nationalFactor ?? 1;
    }

    const handleCheckboxChange = (event: any) => {
        setIsCheckboxChecked(event.target.checked);
    };

    function determinateSeason (date: string): string {
        const month = new Date(date).getMonth() + 1
        const season_id = seasonByMonths?.find(particularMonth => particularMonth.id === month)?.season_id;
        const season_name = seasons.find(particularSeason => particularSeason.id === season_id)?.season_name ?? 'Temporada baja';
        return season_name;
    }

    const goBackToFirstStep = () => {
        setStep(1);
    }

    if (!isAuthenticated) {
        return (
            <BlockScreen/>
        )
    }

    if (isLoading) {
        return (<Loader message={"Cargando formulario..."}/>)
    }

    else {

    return (
        <form className="bg-sectionBarBackgroundColor shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={formik.handleSubmit}>
            { (step === 1) && 
                (
                <div className="mb-6">
                    <div className="flex flex-row justify-between">
                        <h2 className="text-gray-700 text-lg font-bold mb-4">Datos iniciales del viático</h2>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                id="is_it_for_another_employee"
                                className="form-checkbox h-5 w-5 text-gray-600 mr-3 my-5"
                                value={String(formik.values.is_it_for_another_employee)}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <span className="text-gray-700 mr-4">Quiero solicitar un viático en nombre de otra persona.</span>
                        </label>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                        { formik.values.is_it_for_another_employee && 
                            (
                                <div>
                                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_rut">
                                        RUT del funcionario <span className="italic">(requerido)</span>
                                    </label>
                                    <input
                                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="requesting_employee_rut"
                                        name="requesting_employee_rut"
                                        value={formik.values.requesting_employee_rut}
                                        onChange={handleRUTChange}
                                        onBlur={formik.handleBlur}
                                        disabled={!!requestData}
                                    />

                                    {formik.touched.requesting_employee_rut && formik.errors.requesting_employee_rut ? renderFormikError(formik.errors.requesting_employee_rut) : null}

                                </div>
                        )}

                        { formik.values.is_it_for_another_employee && 
                            (
                                <div>
                                </div>
                            )
                        }

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_unit_id">
                                Unidad solicitante <span className="italic">(requerido)</span>
                            </label>
                            <select
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="requesting_unit_id"
                                name="requesting_unit_id"
                                value={formik.values.requesting_unit_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={!!requestData}
                            >
                                <option value="">Seleccione una unidad organizacional.</option>
                                {organizationalUnits.map(unit => (
                                    <option key={unit.organizational_unit_id} value={unit.organizational_unit_id}>
                                        {unit.organizational_unit_name}
                                    </option>
                                ))}
                            </select>

                            {formik.touched.requesting_unit_id && formik.errors.requesting_unit_id ? renderFormikError(formik.errors.requesting_unit_id) : null}

                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="benefit_center_prc_code">
                                Centro de beneficio <span className="italic">(requerido)</span>
                            </label>
                            <select
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="benefit_center_prc_code"
                                name="benefit_center_prc_code"
                                value={formik.values.benefit_center_prc_code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={!!requestData}
                            >
                                <option value="">Seleccione un centro de beneficio.</option>
                                { benefitCenters.filter((center: any) => center.unit_id === formik.values.requesting_unit_id)
                                    .map((center: any) => (
                                        <option key={center.id} value={center.prc_code}>
                                            {center.prc_code} - {center.benefit_center_name}
                                        </option>
                                        )
                                    )
                                }
                            </select>
                            {formik.touched.benefit_center_prc_code && formik.errors.benefit_center_prc_code ? renderFormikError(formik.errors.benefit_center_prc_code) : null}
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="project_number">
                                Ingrese número de proyecto
                            </label>
                            <div className="flex flex-row items-center">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    className="form-checkbox h-5 w-5 text-gray-600 mr-3"
                                    checked={hasNoProjectNumber}
                                    onChange={(e) => {
                                        setHasNoProjectNumber(e.target.checked);
                                        if (!e.target.checked) {
                                            formik.setFieldValue('project_number', '');
                                        } else {
                                            formik.setFieldValue('project_number', 'No tiene número de proyecto');
                                        }
                                    }}
                                />
                                <span className="text-gray-700 mr-4">No tiene.</span>
                            </label>
                            <input
                                className="shadow border ml-4 w-full rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="project_number"
                                name="project_number"
                                value={formik.values.project_number}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={hasNoProjectNumber}
                            />
                        </div>
                        {formik.touched.project_number && formik.errors.project_number ? renderFormikError(formik.errors.project_number) : null}
                        </div>

                        <div>
                            <label htmlFor="viatic_type" className="block text-gray-700 text-sm font-semibold mb-2">
                                Tipo de viático
                            </label>
                            <select
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="viatic_type"
                                onChange={formik.handleChange}
                                value={formik.values.viatic_type}
                                onBlur={formik.handleBlur}
                                disabled={!!requestData}
                            >
                                <option value="">Seleccione un tipo de viático.</option>
                                <option value="Nacional - Región Metropolitana"> Viaje nacional - Región Metropolitana </option>
                                <option value="Nacional - Otras regiones"> Viaje nacional - Otras regiones </option>
                                <option value="Internacional"> Viaje internacional </option>
                            </select>
                            {formik.touched.viatic_type && formik.errors.viatic_type ? renderFormikError(formik.errors.viatic_type) : null}
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requires_deposit">
                                ¿Requiere depósito? <span className="italic">(requerido)</span>
                            </label>
                            <select
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="requires_deposit"
                                name="requires_deposit"
                                value={String(formik.values.requires_deposit)}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <option value="">Selecciona una opción.</option>
                                {requireDepositOptions.map((option: any) => (
                                    <option key={option.id} value={option.id}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>

                            {formik.touched.requires_deposit && formik.errors.requires_deposit ? renderFormikError(formik.errors.requires_deposit) : null}
                        </div>

                        { (formik.values.requires_deposit === "true") && (
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="bank_name">
                                    Banco <span className="italic">(requerido)</span>
                                </label>
                                <input
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="bank_name"
                                    name="bank_name"
                                    value={formik.values.bank_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                {formik.touched.bank_name && formik.errors.bank_name ? renderFormikError(formik.errors.bank_name) : null}
                            </div>
                        )}

                        { (formik.values.requires_deposit === "true") && (
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="account_type">
                                    Tipo de cuenta <span className="italic">(requerido)</span>
                                </label>
                                <select
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="account_type"
                                    onChange={formik.handleChange}
                                    value={formik.values.account_type}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value=""> Selecciona una de las opciones </option>
                                    <option value="Cuenta vista"> Cuenta vista </option>
                                    <option value="Cuenta corriente"> Cuenta corriente </option>
                                    <option value="Cuenta de ahorro"> Cuenta de ahorro </option>
                                    <option value="Cuenta chequera electrónica"> Cuenta chequera electrónica </option>
                                </select>
                                {formik.touched.account_type && formik.errors.account_type ? renderFormikError(formik.errors.account_type) : null}
                            </div>
                        )}

                        { (formik.values.requires_deposit === "true") && (
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="account_number">
                                    Número de cuenta <span className="italic">(requerido)</span>
                                </label>
                                <input
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="account_number"
                                    name="account_number"
                                    value={formik.values.account_number}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                        
                                {formik.touched.account_number && formik.errors.account_number ? renderFormikError(formik.errors.account_number) : null}
                        
                            </div>
                        )}

                        <div className="grid grid-cols-1">
                            <button className="mt-4 align-baseline text-white w-full bg-blue-400 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">
                                Siguiente
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        { (step === 2) && (
        <div className="mb-6">
            <h2 className="text-gray-700 text-lg font-bold mb-4">Datos del viático</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="fullname">
                        Fecha de inicio del viaje
                    </label>
                    <div className="w-full">
                        <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                            <DatePicker
                                label="Fecha"
                                minDate={dayjs().subtract(15, 'day')}
                                value={formik.values.travel_start_date ? dayjs(formik.values.travel_start_date) : null}
                                onChange={handleStartDateChange}
                                className="w-full"
                           />
                        </LocalizationProvider>
                        </ThemeProvider>
                    </div>
                </div>


                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="fullname">
                        Fecha de término del viaje
                    </label>
                    <div className="w-full">
                        <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                            <DatePicker
                                label="Fecha"
                                minDate={formik.values.travel_start_date ? dayjs(formik.values.travel_start_date).add(0, 'day') : dayjs()}
                                value={formik.values.travel_end_date ? dayjs(formik.values.travel_end_date) : null}
                                onChange={handleFinishDateChange}
                                className="w-full h-6/12"
                            />
                        </LocalizationProvider>
                        </ThemeProvider>
                        {formik.touched.travel_end_date && formik.errors.travel_end_date ? renderFormikError(formik.errors.travel_end_date) : null}

                    </div>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="days_spent">
                        Días de duración del viaje
                    </label>
                    <input
                        className="bg-white text-center shadow border h-6/12 rounded w-6/12 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="days_spent"
                        {...formik.getFieldProps('days_spent')}
                        disabled
                    />
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2 mt-10" htmlFor="trip_reason">
                        Motivo(s) del viaje <span className="italic">(requerido)</span>
                    </label>
                    <input
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="trip_reason"
                        name="trip_reason"
                        value={formik.values.trip_reason}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />

                    {formik.touched.trip_reason && formik.errors.trip_reason ? renderFormikError(formik.errors.trip_reason) : null}
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2 mt-10" htmlFor="requested_vehicle_id">
                        Vehículo para el viaje
                    </label>
                    <select
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="requested_vehicle_id"
                        name="requested_vehicle_id"
                        value={formik.values.requested_vehicle_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={!formik.values.travel_start_date || !formik.values.travel_end_date || isLoading}
                    >
                        {isLoading ? (
                            <option value="">Buscando vehículos disponibles...</option>
                        ) : availableVehicles.length > 0 ? (
                            <>
                                <option value="">Seleccione un vehículo.</option>
                                <option value="">No se requiere vehículo.</option>
                                {availableVehicles.map((vehicle: any) => (
                                    <option key={vehicle.id} value={vehicle.id}>
                                        {vehicle.vehicle_plate}
                                    </option>
                                ))}
                            </>
                        ) : (
                            <option value="">No hay vehículos disponibles.</option>
                        )}
                    </select>
                </div>

                <div></div>

            </div>
            <div>
                <div className="mb-10 mt-10">
                    <h2 className="block text-gray-700 text-md font-bold mb-10 text-left">
                        Cálculo del viático
                    </h2>


                    {/* Nacional - Región Metropolitana */}

                    { (formik.values.viatic_type === 'Nacional - Región Metropolitana') && (
                            <div className="w-full content-center">
                                <div className="grid md:grid-cols-4 gap-4">
                                    <div>
                                        <label htmlFor="breakfast_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de solo desayuno <span className="italic">(requerido)</span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="breakfast_days_only"
                                            name="breakfast_days_only"
                                            type="number"
                                                value={formik.values.breakfast_days_only}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                min="0"
                                                max={formik.values.days_spent}
                                            />
                                            {formik.touched.breakfast_days_only && formik.errors.breakfast_days_only ? renderFormikError(formik.errors.breakfast_days_only) : null}

                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {metropolitanRegionTripConstants.find((constant) => constant.item === 'breakfast_days_only')?.uf_amount || 0} UF</div>
                                    <div className="text-center text-gray-700 font-semibold">= {(formik.values.breakfast_days_only * (metropolitanRegionTripConstants.find((constant) => constant.item === 'breakfast_days_only')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                            <label htmlFor="lunch_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                                Días de solo almuerzo <span className="italic">(requerido)</span>
                                            </label>
                                    </div>
                                            
                                    <div>
                                        <input
                                                className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="lunch_days_only"
                                                name="lunch_days_only"
                                                type="number"
                                                value={formik.values.lunch_days_only}
                                                onBlur={formik.handleBlur}
                                                min="0"
                                                max={formik.values.days_spent}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.lunch_days_only && formik.errors.lunch_days_only ? renderFormikError(formik.errors.lunch_days_only) : null}
                                            
                                    </div>
                                        <div className="text-center text-gray-700 font-semibold">x {metropolitanRegionTripConstants.find((constant) => constant.item === 'lunch_days_only')?.uf_amount || 0} UF</div>
                                        <div className="text-center text-gray-700 font-semibold">= {(formik.values.lunch_days_only * (metropolitanRegionTripConstants.find((constant) => constant.item === 'lunch_days_only')?.uf_amount ?? 0)).toFixed(3)} UF</div>
                                    
                                    <div>
                                            <label htmlFor="dinner_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                                Días de solo cena <span className="italic">(requerido)</span>
                                            </label>
                                    </div>
                                    <div>
                                        <input
                                                className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="dinner_days_only"
                                                name="dinner_days_only"
                                                type="number"
                                                value={formik.values.dinner_days_only}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                min="0"
                                                max={formik.values.days_spent}
                                            />
                                            {formik.touched.dinner_days_only && formik.errors.dinner_days_only ? renderFormikError(formik.errors.dinner_days_only) : null}

                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {metropolitanRegionTripConstants.find((constant) => constant.item === 'dinner_days_only')?.uf_amount || 0} UF</div>
                                    <div className="text-center text-gray-700 font-semibold">= {(formik.values.dinner_days_only * (metropolitanRegionTripConstants.find((constant) => constant.item === 'dinner_days_only')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                            <label htmlFor="meal_vouchers_discount" className="block text-gray-700 text-sm font-semibold mr-5">
                                                Descuentos por vale de colación <span className="italic">(requerido)</span>
                                            </label>
                                    </div>

                                    <div>
                                            <input
                                                className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="meal_vouchers_discount"
                                                name="meal_vouchers_discount"
                                                type="number"
                                                value={formik.values.meal_vouchers_discount}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                min="0"
                                            />
                                    
                                        {formik.touched.meal_vouchers_discount && formik.errors.meal_vouchers_discount ? renderFormikError(formik.errors.meal_vouchers_discount) : null}

                                    
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {metropolitanRegionTripConstants.find((constant) => constant.item === 'meal_voucher_discount_quantity')?.uf_amount || 0} UF</div>
                                    <div className="text-center text-gray-700 font-semibold">= - {(formik.values.meal_vouchers_discount * (metropolitanRegionTripConstants.find((constant) => constant.item === 'meal_voucher_discount_quantity')?.uf_amount ?? 0)).toFixed(3)} UF</div>
                                </div>
                            </div>
                        )}




                        {/* Nacional - Otras regiones */}

                        { (formik.values.viatic_type === 'Nacional - Otras regiones') && (
                            <div className="w-full content-center">
                                <div className="grid md:grid-cols-5 gap-4">

                                    <div>
                                        <label htmlFor="destination_region_id" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Región de destino <span className="italic">(requerido)</span>
                                        </label>
                                    </div>
                                    <div>
                                        <select
                                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="destination_region_id"
                                            name="destination_region_id"
                                            value={formik.values.destination_region_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            <option value="">Seleccione una región.</option>
                                            {(nationalFactors.length > 0) ? (
                                                nationalFactors.map((region: any) => (
                                                    <option key={region.id} value={region.id}>
                                                        {region.region_name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option key={''} value={''}>
                                                    No hay regiones disponibles.
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    <div> </div>
                                    <div> </div>
                                    <div> </div>

                                    <div>
                                        <label htmlFor="season_id" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Temporada
                                        </label>
                                    </div>
                                    <div>
                                        <label htmlFor="season_id" className="block text-gray-700 text-sm font-semibold mr-5">
                                            {[1, 2, 3, 12].includes((new Date(formik.values.travel_start_date)).getMonth() + 1) ? 'Alta' : 'Baja'}
                                        </label>
                                    </div>
                                    <div> </div>
                                    <div> </div>
                                    <div> </div>

                                    <div> </div>
                                    <div> </div>
                                    <div> </div>
                                    <div> 
                                        <label htmlFor="adjustmentFactor" className="block text-gray-700 text-sm font-semibold mr-5 text-center">
                                            Factor de ajuste por región y temporada: 
                                        </label>    
                                    </div>
                                    <div> 
                                        <label htmlFor="total_value" className="align-text-bottom block text-gray-700 text-sm font-semibold mr-5 text-center">
                                            Total
                                        </label>
                                    </div>
                                    <div>
                                        <label htmlFor="breakfast_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de solo desayuno <span className="italic">(requerido)</span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="breakfast_days_only"
                                            name="breakfast_days_only"
                                            type="number"
                                                value={formik.values.breakfast_days_only}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                min="0"
                                                max={formik.values.days_spent}
                                            />
                                            {formik.touched.breakfast_days_only && formik.errors.breakfast_days_only ? renderFormikError(formik.errors.breakfast_days_only) : null}

                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'breakfast_days_only')?.uf_amount || 0} UF</div>
                                    {getNationalFactor()}
                                    <div className="text-center text-gray-700 font-semibold">= {(getNationalFactorValue() * formik.values.breakfast_days_only * (nationalTripConstants.find((constant) => constant.item === 'breakfast_days_only')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                            <label htmlFor="lunch_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                                Días de solo almuerzo <span className="italic">(requerido)</span>
                                            </label>
                                    </div>
                                            
                                    <div>
                                        <input
                                                className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="lunch_days_only"
                                                name="lunch_days_only"
                                                type="number"
                                                value={formik.values.lunch_days_only}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                min="0"
                                                max={formik.values.days_spent}
                                            />
                                        {formik.touched.lunch_days_only && formik.errors.lunch_days_only ? renderFormikError(formik.errors.lunch_days_only) : null}

                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'lunch_days_only')?.uf_amount || 0} UF</div>
                                    {getNationalFactor()}
                                    <div className="text-center text-gray-700 font-semibold">= {(getNationalFactorValue() * formik.values.lunch_days_only * (nationalTripConstants.find((constant) => constant.item === 'lunch_days_only')?.uf_amount ?? 0)).toFixed(3)} UF</div>
                                    
                                    <div>
                                            <label htmlFor="dinner_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                                Días de solo cena <span className="italic">(requerido)</span>
                                            </label>
                                    </div>
                                    <div>
                                        <input
                                                className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="dinner_days_only"
                                                name="dinner_days_only"
                                                type="number"
                                                value={formik.values.dinner_days_only}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                min="0"
                                                max={formik.values.days_spent}
                                            />
                                            {formik.touched.dinner_days_only && formik.errors.dinner_days_only ? renderFormikError(formik.errors.dinner_days_only) : null}

                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'dinner_days_only')?.uf_amount || 0} UF</div>
                                    {getNationalFactor()}
                                    <div className="text-center text-gray-700 font-semibold">= {(getNationalFactorValue() * formik.values.dinner_days_only * (nationalTripConstants.find((constant) => constant.item === 'dinner_days_only')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                        <label htmlFor="full_meal_days" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de alimentación completa <span className="italic">(requerido)</span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="full_meal_days"
                                            name="full_meal_days"
                                            type="number"
                                            value={formik.values.full_meal_days}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            min="0"
                                            max={formik.values.days_spent}
                                        />
                                    
                                        {formik.touched.full_meal_days && formik.errors.full_meal_days ? renderFormikError(formik.errors.full_meal_days) : null}

                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'full_meal_days')?.uf_amount || 0} UF</div>
                                    {getNationalFactor()}
                                    <div className="text-center text-gray-700 font-semibold">= {(getNationalFactorValue() * formik.values.full_meal_days * (nationalTripConstants.find((constant) => constant.item === 'full_meal_days')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                        <label htmlFor="housing_days" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de alojamiento <span className="italic">(requerido)</span>
                                        </label>
                                    </div>

                                    <div>
                                        <input
                                            className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="housing_days"
                                            name="housing_days"
                                            type="number"
                                            value={formik.values.housing_days}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            min="0"
                                            max={formik.values.days_spent}
                                        />
                                    
                                        {formik.touched.housing_days && formik.errors.housing_days ? renderFormikError(formik.errors.housing_days) : null}

                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'housing_days')?.uf_amount || 0} UF</div>
                                    {getNationalFactor()}
                                    <div className="text-center text-gray-700 font-semibold">= {(getNationalFactorValue() * formik.values.housing_days * (nationalTripConstants.find((constant) => constant.item === 'full_meal_days')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                        <label htmlFor="housing_days" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de bono compensatorio <span className="italic">(requerido)</span>
                                        </label>
                                    </div>

                                    <div>
                                        <input
                                            className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="compensatory_bonus_quantity"
                                            name="compensatory_bonus_quantity"
                                            type="number"
                                            value={formik.values.compensatory_bonus_quantity}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            min="0"
                                            max={formik.values.days_spent}
                                        />
                                    
                                        {formik.touched.compensatory_bonus_quantity && formik.errors.compensatory_bonus_quantity ? renderFormikError(formik.errors.compensatory_bonus_quantity) : null}

                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'compensatory_bonus')?.uf_amount || 0} UF</div>
                                    <div> </div>
                                    <div className="text-center text-gray-700 font-semibold">= {(formik.values.compensatory_bonus_quantity * (nationalTripConstants.find((constant) => constant.item === 'compensatory_bonus')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                        <label htmlFor="meal_vouchers_discount" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Descuentos por vale de colación <span className="italic">(requerido)</span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="text-center shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="meal_vouchers_discount"
                                            name="meal_vouchers_discount"
                                            type="number"
                                            value={formik.values.meal_vouchers_discount}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            min="0"
                                            max={formik.values.days_spent}
                                        />
                                        {formik.touched.meal_vouchers_discount && formik.errors.meal_vouchers_discount ? renderFormikError(formik.errors.meal_vouchers_discount) : null}
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'meal_voucher_discount_quantity')?.uf_amount || 0} UF</div>
                                    <div></div>
                                    <div className="text-center text-gray-700 font-semibold">= - {( formik.values.meal_vouchers_discount * (nationalTripConstants.find((constant) => constant.item === 'meal_voucher_discount_quantity')?.uf_amount ?? 0)).toFixed(3)} UF</div>
                                
                                </div>
                            </div>
                        )}


                        {/* Internacional */}

                        { (formik.values.viatic_type === 'Internacional') && (
                            <div className="w-full content-center">
                                <div className="grid md:grid-cols-5 gap-4">
                                <div> </div>
                                    <div> </div>
                                    <div> </div>
                                    <div> 
                                        <label htmlFor="adjustmentFactor" className="block text-gray-700 text-sm font-semibold mr-5 text-center">
                                            Factor de ajuste por país de destino: {internationalFactors.find(country => country.id === Number(formik.values.destination_country_id))?.factor}
                                        </label>    
                                    </div>
                                    <div> 
                                        <label htmlFor="total_value" className="align-text-bottom block text-gray-700 text-sm font-semibold mr-5 text-center">
                                            Total
                                        </label>
                                    </div>

                                    <div>
                                        <label htmlFor="breakfast_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Responsabilidad del funcionario <span className="italic">(requerido)</span>
                                        </label>
                                    </div>
                                    <div>
                                        <select
                                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="employee_responsibility_type"
                                            onChange={formik.handleChange}
                                            value={formik.values.employee_responsibility_type}
                                            onBlur={formik.handleBlur}
                                        >
                                            <option value="">Seleccione un cargo.</option>
                                            <option value="Encargado (a) de área (gerente)"> Encargado (a) de área (gerente) </option>
                                            <option value="Colaborador (a) de proyecto"> Colaborador (a) de proyecto </option>
                                        </select>
                                        {formik.touched.employee_responsibility_type && formik.errors.employee_responsibility_type ? renderFormikError(formik.errors.employee_responsibility_type) : null}

                                    </div>
                                    <div></div>
                                    <div></div>
                                    <div></div>

                                    <div>
                                        <label htmlFor="destination_country_id" className="block text-gray-700 text-sm font-semibold mr-5">
                                            País de destino <span className="italic">(requerido)</span>
                                        </label>
                                    </div>
                                    <div>
                                        <select
                                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="destination_country_id"
                                            name="destination_country_id"
                                            value={formik.values.destination_country_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            <option value="">Seleccione un país.</option>
                                            {(internationalFactors.length > 0) ? (
                                                internationalFactors.map((country: any) => (
                                                    <option key={country.id} value={country.id}>
                                                        {country.country_name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option key={''} value={''}>
                                                    No hay países disponibles.
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    <div></div>
                                    <div> </div>
                                    <div> </div>

                                    <div>
                                        <label htmlFor="destination_city" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Ciudad de destino <span className="italic">(requerido)</span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="destination_city"
                                            name="destination_city"
                                            value={formik.values.destination_city}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />

                                        {formik.touched.destination_city && formik.errors.destination_city ? renderFormikError(formik.errors.destination_city) : null}
                                        
                                    </div>
                                    <div> </div>
                                    <div> </div>
                                    <div> </div>
                                                                          
                                    <div>
                                        <label htmlFor="full_day_basic_viatic" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de viático - Día completo <span className="italic">(requerido)</span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                                className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="full_day_basic_viatic"
                                                name="full_day_basic_viatic"
                                                type="number"
                                                value={formik.values.full_day_basic_viatic}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                min="0"
                                                max={formik.values.days_spent}
                                            />
                                        {formik.touched.half_day_basic_viatic && formik.errors.half_day_basic_viatic ? renderFormikError(formik.errors.full_day_basic_viatic) : null}
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {(internationalAllowanceByRoles?.find(role => role.role === formik.values.employee_responsibility_type)?.usd ?? 0)} USD </div>
                                    <div className="text-center text-gray-700 font-semibold">x {internationalFactors.find(country => country.id === Number(formik.values.destination_country_id))?.factor} </div>
                                    <div className="text-center text-gray-700 font-semibold">= {(formik.values.full_day_basic_viatic * (internationalAllowanceByRoles?.find(role => role.role === formik.values.employee_responsibility_type)?.usd ?? 0) * (internationalFactors?.find(country => country.id === Number(formik.values.destination_country_id))?.factor ?? 0)).toFixed(2)} USD</div>

                                    <div>
                                        <label htmlFor="half_day_basic_viatic" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de viático - Día de trayecto (50%)<span className="italic">(requerido)</span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                                className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="half_day_basic_viatic"
                                                name="half_day_basic_viatic"
                                                type="number"
                                                min="0"
                                                value={formik.values.half_day_basic_viatic}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                max={formik.values.days_spent - formik.values.full_day_basic_viatic}
                                            />
                                            {formik.touched.half_day_basic_viatic && formik.errors.half_day_basic_viatic ? renderFormikError(formik.errors.half_day_basic_viatic) : null}
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {0.5 * (internationalAllowanceByRoles?.find(role => role.role === formik.values.employee_responsibility_type)?.usd ?? 0)} USD </div>
                                    <div className="text-center text-gray-700 font-semibold">x {internationalFactors.find(country => country.id === Number(formik.values.destination_country_id))?.factor} </div>
                                    <div className="text-center text-gray-700 font-semibold">= {(formik.values.half_day_basic_viatic * 0.5 * (internationalAllowanceByRoles?.find(role => role.role === formik.values.employee_responsibility_type)?.usd ?? 0) * (internationalFactors?.find(country => country.id === Number(formik.values.destination_country_id))?.factor ?? 0)).toFixed(2)} USD</div>
                                </div>
                            </div>
                        )}
                </div>

                <div className="mb-4">
                    <label htmlFor="total" className="block text-gray-700 text-sm font-bold mb-2">
                        Total del viático
                    </label>
                    <div className="flex items-center flex-start">
                        {totalAmount.toFixed(3)} {" "} {
                            (formik.values.viatic_type === 'Internacional') ? <div> USD </div> : <div> UF </div>
                        }
                    </div>
                </div>

                <div className="mb-4">
                    <label className="inline-flex items-center mt-3">
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-gray-600"
                            checked={isCheckboxChecked}
                            onChange={handleCheckboxChange}
                        />
                        <span className="ml-2 text-gray-700 text-sm">
                            Confirmo que la información ingresada es verídica.
                        </span>
                    </label>
                </div>

                <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
                    <button
                        className={`font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white`}
                        onClick={() => goBackToFirstStep()}
                    >
                        Volver
                    </button>
                    <div> </div>
                    <button
                        className={`font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${isCheckboxChecked ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-500 hover:bg-gray-700 cursor-not-allowed'} text-white`}
                        type="submit"
                        disabled={!isCheckboxChecked}
                    >
                        {requestData ? <div> Modificar solicitud</div> : <div> Enviar </div>}
                    </button>
                </div>
            </div>
        </div>
    )}
    </form>
  )}
}

function calculateInclusiveDaysBetweenDates(startDate: string, endDate: string) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDifference: number = end.getTime() - start.getTime();
    const daysDifference: number = timeDifference / (1000 * 3600 * 24);
    return Math.round(daysDifference) + 1;
}

export default TravelAllowanceRequestForm;